<template>
  <div class="wrapper">
    <div class="top">
      <span>兑换成功</span>
    </div>
    <div class="content">
      <div class="content-top">您已成功兑换</div>
      <div class="content-bottom">
        <div style="text-align: center;margin-top: 50px; font-weight: 600;">
          <p style="font-size: 20px;">您的礼品已成功兑换</p>
          <p style="margin-top: 10px;font-size: 16px;">我们会为您尽快发货</p>
        </div>
        <div style="margin-top: 50px;font-size: 14px;padding: 0 20px;">
          <van-button @click="Logistic" round block type="primary" color="red">
            <span style="font-size: 18px;">查看物流信息</span>
          </van-button>
        </div>
        <div style="font-size: 12px; text-align: center;margin-top: 10px;">在首页再次输入卡号卡密可查看物流信息</div>
      </div>
    </div>
    <div class="btm">
      卡券兑换成功后，卡号将会失效
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  components: {},
  data () {
    return {
    }
  },
  computed: {},
  methods: {
    async Logistic () {
      try {
        const arr = this.$store.state.orderId
        const params = {
          id: arr.orderId
        }
        // console.log(params)
        const orderList = await this.$api.contacts.orderDetail(params)
        if (orderList.code === 20000) {
          // 获取当前URL
          const currentUrl = window.location.href
          // 提取URL的后缀部分
          const suffixIndex = currentUrl.lastIndexOf('/')
          const suffix = suffixIndex !== -1 ? currentUrl.slice(suffixIndex) : ''
          this.$store.commit('setmyLogisticsCode', { LogisticsCode: orderList.data })
          // this.navigateWithSuffix('/public/giftSelect/logistics' + suffix)
          this.$router.push('/public/giftSelect/logistics' + suffix)
        } else {
          Toast(orderList.message)
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  mounted () {}
}
</script>
<style scoped lang="scss">
.top {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(255, 44, 0);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.wrapper {
    background-color: #d8d8d8;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content-top {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    align-items: center;
    color: #fff;
    background-color: #FF2C00;
    width: 300px;
    height: 80px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 24px;
}
.content-bottom {
    width: 280px;
    height: 280px;
    background-color: #fff;
}
.btm {
    text-align: center;
    margin-top: 100px;
    font-weight: bold;
    font-size: 14px;
}
</style>
