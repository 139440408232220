<template>
  <div class="wrapper">
    <div class="top-mune" @click="back"><van-icon name="arrow-left" /></div>
    <div class="top">
      <span>提货说明</span>
    </div>
    <div style="margin: 10px 20px;">{{ this.$store.state.myGoods.myGoods }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
    }
  },
  computed: {},
  methods: {
    back () {
      this.$router.go(-1)
    }
  },
  mounted () {}
}
</script>
<style scoped>
.wrapper {
  background-color: #d8d8d8;
}
.top {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(255, 44, 0);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.top-mune {
  position: absolute;
  color: #fff;
  font-size: 16px;
  line-height: 45px;
  margin-left: 10px;
}
</style>
