<template>
  <div class="wrapperTwo">
    <div class="top-mune" @click="back"><van-icon name="arrow-left" /></div>
    <div class="top">
      <span>收货信息</span>
    </div>
    <div class="content">
      <div style="padding-top: 10px;">
        <div style="display: flex; justify-content: space-between;"><h4 style="margin-top: 10px;">选择礼包</h4><span style="margin-top: 12px;"><van-icon @click="backGift" name="arrow" /></span></div>
        <div class="item" v-for="item in plainOptions.checkId" :key="item.Newindex">
          <div style="width: 30%;display: flex; align-items: center;justify-content: center;">
            <van-image
              width="100px"
              height="100px"
              fit="cover"
              :src="item.img"
            />
          </div>
          <div class="item-text" style="width: 62%; overflow-wrap: break-word;">
            <p>{{ item.name }}</p>
            <p style="font-size: 12px; color: #D8D8D8; margin: 10px 0;">{{ item.intro }}</p>
            <p style="font-size: 12px; color: #D8D8D8; margin: 5px 0;">{{ item.price }}</p>
            <p style="font-size: 12px; color: #D8D8D8;">x1</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 收货信息 -->
    <div class="info">
      <h4>收货信息</h4>
      <div>
        <van-address-edit
          :area-list="areaList"
          :show-area="true"
          detail-rows="5"
          save-button-text="确认"
          :area-columns-placeholder="['请选择', '请选择', '请选择']"
          @save="onSave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { areaList } from '@vant/area-data'
export default {
  components: {},
  data () {
    return {
      plainOptions: [],
      areaList
    }
  },
  computed: {},
  methods: {
    back () {
      this.$router.go(-1)
    },
    async onSave (content) {
      const arr = this.$store.state.checkId
      const arr1 = this.$store.state.couponId
      const params = {
        consigneeAddress: content.addressDetail,
        consigneeCity: content.city,
        consigneeCountry: content.county,
        consigneeName: content.name,
        consigneePhone: content.tel,
        consigneeProvince: content.province,
        couponId: arr1.couponId,
        goodsIdList: (arr.checkId).map(item => {
          return item.id
        })
      }
      const orderList = await this.$api.contacts.order(params)
      if (orderList.code === 20000) {
        // 获取当前URL
        const currentUrl = window.location.href
        // 提取URL的后缀部分
        const suffixIndex = currentUrl.lastIndexOf('/')
        const suffix = suffixIndex !== -1 ? currentUrl.slice(suffixIndex) : ''
        this.$store.commit('setmyOrderId', { orderId: orderList.data.orderId })
        this.$router.push('/public/giftSelect/changeSuccess' + suffix)
        // this.navigateWithSuffix('/public/giftSelect/changeSuccess')
      } else {
        Toast(orderList.message)
      }
    },
    backGift () {
      this.$router.go(-1)
    },
    async request () {
      const params = {
        parentId: 0
      }
      const result = await this.$api.contacts.areas(params)
      if (result.code === 20000) {
        // this.areaList = result.data
        // console.log(result, '333')
      } else {
        Toast(result.message)
      }
    }
  },
  mounted () {},
  created () {
    this.plainOptions = this.$store.state.checkId
    this.request()
  }
}
</script>
<style scoped lang="scss">
.wrapperTwo {
  background-color: #d8d8d8;
}
.content {
    position: relative;
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
}
.top {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(255, 44, 0);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.top-mune {
  position: absolute;
  color: #fff;
  font-size: 16px;
  line-height: 45px;
  margin-left: 10px;
}
.item {
  display: flex;
  height: 100px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  /* margin: 10px; */
  /* margin: 10px 0; */
  border-radius: 10px;
  .item-text {
    display: flex;
    flex-direction: column;
    margin: 10px 10px 0;
  }
}
.info {
    background-color: #fff;
    /* height: 500px; */
    margin: 0 10px;
    border-radius: 10px;
    padding-top: 10px;
}
h4 {
    margin-left: 10px;
    border-left: solid 8px red;
    padding-left: 8px;
}
</style>
