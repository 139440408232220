<!--
 * @Description:
 * @Author: shufei
 * @Date: 2021-11-13 20:36:01
 * @LastEditTime: 2021-11-19 23:28:06
 * @LastEditors: shufei
-->
<template>
  <div class="container">
    <empty state="5" text="页面找不到"></empty>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
