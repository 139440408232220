<template>
  <div class="wrapper">
    <div class="wrapperOne" v-show="isShow">
      <div class="top-mune" @click="back"><van-icon name="arrow-left" /></div>
      <div class="top">
        <span>选择礼品</span>
      </div>
      <div class="menu">请选择{{ this.maxOrder }}件商品,还可选择{{ this.minOrder }}件商品</div>
      <div class="content">
        <div v-for="item in plainOptions" :key="item.Newindex" :class="[checkedList.length >= maxOrder && !checkedList.includes(item) ? activeTo : active]">
          <div style="width: 8%;display: flex; align-items: center;justify-content: center;">
            <van-checkbox-group v-model="checkedList" :max="maxOrder">
              <van-checkbox
                @click="changehandl(item)"
                :name="item"
                :value="item.Newindex"
                :disabled="checkedList.length >= maxOrder && !checkedList.includes(item)"
                checked-color="#ee0a24"></van-checkbox>
            </van-checkbox-group>
          </div>
          <div style="width: 30%;display: flex; align-items: center;justify-content: center;">
            <van-image
              @click="changeItem(item)"
              width="100px"
              height="100px"
              fit="cover"
              :src="item.images"
            />
          </div>
          <div class="item-text" style="width: 62%; overflow-wrap: break-word;">
            <p class="item-p">{{ item.name }}</p>
            <p class="item-intro" title="item.intro" style="font-size: 12px; color: #aaa; margin: 10px 0;">{{ item.intro }}</p>
            <p style="font-size: 12px; color: #aaa; margin: 10px 0;">{{ item.price }}</p>
            <p style="font-size: 12px;">x1</p>
          </div>
        </div>
      </div>
      <div @click="illustr" style="text-align: center;color: #3F73FA;cursor: pointer;font-size: 16px;">提货说明</div>
      <div style="margin: 20px;">
        <van-button
          @click="Onsubmit"
          round
          block
          type="primary"
          color="red"
          :disabled="checkedList.length < maxOrder">
          <span style="font-size: 18px;">下一步</span>
        </van-button>
      </div>
    </div>
    <div class="wrapperTwo" v-show="!isShow">
      <div class="top-mune" @click="backGift"><van-icon name="arrow-left" /></div>
      <div class="top">
        <span>收货信息</span>
      </div>
      <div class="contenttow">
        <div style="padding-top: 10px;">
          <div style="display: flex; justify-content: space-between;"><h4 style="margin-top: 10px;">选择礼包</h4><span style="margin-top: 12px;"><van-icon @click="backGift" name="arrow" /></span></div>
          <div class="item" style="height: 100px;" v-for="item in checkedList" :key="item.Newindex">
            <div style="width: 30%;display: flex; align-items: center;justify-content: center;">
              <van-image
                width="100px"
                height="100px"
                fit="cover"
                :src="item.images"
              />
            </div>
            <div class="item-text" style="width: 62%; overflow-wrap: break-word;">
              <p class="item-p">{{ item.name }}</p>
              <p class="item-intro" style="font-size: 12px; color: #aaa; margin: 10px 0;">{{ item.intro }}</p>
              <p style="font-size: 12px; color: #aaa; margin: 5px 0;">{{ item.price }}</p>
              <p style="font-size: 12px; color: #000;">x1</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 收货信息 -->
      <div class="info">
        <h4>收货信息</h4>
        <div>
          <!-- <van-address-edit
            :area-list="areaList"
            :show-area="true"
            detail-rows="5"
            save-button-text="确认"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
          /> -->
          <van-form @submit="onSubmit">
            <van-cell-group inset>
              <van-field
                v-model="consigneeName"
                name="收货人"
                label="收货人:"
                placeholder="请输入收货人"
                :rules="[{ required: true, message: '请填写收货人' }]"
              />
              <van-field
                v-model="consigneePhone"
                type="consigneePhone"
                name="手机号码"
                label="手机号码:"
                placeholder="请输入手机号码"
                :rules="[{ required: true, message: '请填写手机号码' }, { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }]"
              />
              <!-- 弹出层 -->
              <van-field
                readonly
                is-link
                label="所在地区"
                :rules="[{ required: true, message: '请输入所在地区' }]"
                @click="showPopup = true"
                :value="valueArea"
                placeholder="请选择所在地区"></van-field>
              <van-popup v-model="showPopup" position="bottom">
                <!-- 省市区选择组件 -->
                <van-area
                  :area-list="areaList"
                  :columns-placeholder="['请选择', '请选择', '请选择']"
                  title="标题"
                  @change="handleChange"
                  @confirm="onAreaConfirm"
                  @cancel="onAreaCancel"
                />
              </van-popup>
              <van-field
                type="textarea"
                v-model="consigneeAddress"
                label="详细地址"
                placeholder="请输入详细地址"
                :rules="[{ required: true, message: '请输入详细地址' }]"
                rows="5"
                autosize
              />
              <div style="margin: 25px;text-align: center;">
                <van-button round block type="primary" native-type="submit" color="red">
                  <span style="font-size: 18px;">确认</span>
                </van-button>
              </div>
            </van-cell-group>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, ImagePreview } from 'vant'
// import { areaList } from '@vant/area-data'
export default {
  name: 'SelectGift',
  components: {},
  data () {
    return {
      maxOrder: null,
      minOrder: null,
      plainOptions: [],
      checkedList: [], // 保存选中的值
      disabled: false, // checkedList.length >= 2 && !checkedList.includes(item.name)
      active: 'item',
      activeTo: 'itemto',
      isShow: true,
      showPopup: false, // 控制弹出层的显示与隐藏
      currentLevels: '',
      // areaList,
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {}
      }, // 存储地区列表
      rawAreaList: null, // 当前选中的地区级别
      consigneeName: '', // 收货人
      consigneePhone: '', // 手机号
      consigneeProvince: '', // 省
      consigneeCity: '', // 市
      consigneeCountry: '', // 县
      consigneeAddress: '', // 详细地址
      valueArea: '', // 地区值
      arrArea: [], // 存放地区数组
      ImagePreview: []
    }
  },
  computed: {},
  methods: {
    back () {
      this.$router.go(-1)
    },
    backGift () {
      this.isShow = true
    },
    illustr () {
      // 获取当前URL
      const currentUrl = window.location.href
      // 提取URL的后缀部分
      const suffixIndex = currentUrl.lastIndexOf('/')
      const suffix = suffixIndex !== -1 ? currentUrl.slice(suffixIndex) : ''
      this.$router.push('/public/giftSelect/illustrate' + suffix)
      // this.navigateWithSuffix('/public/giftSelect/illustrate')
    },
    Onsubmit () {
      this.isShow = false
    },
    onAreaConfirm (val) {
      // 当用户选择省市区后触发的回调函数
      this.arrArea = val
      var addrInfo = val[0].name + '-' + val[1].name + '-' + val[2].name
      this.consigneeProvince = val[0].name
      this.consigneeCity = val[1].name
      this.consigneeCountry = val[2].name
      this.valueArea = addrInfo
      console.log(this.valueArea)
      this.showPopup = false // 关闭弹出层
    },
    onAreaCancel () {
      // 当用户取消选择时触发的回调函数
      this.showPopup = false // 关闭弹出层
    },
    async request () {
      try {
        const result = await this.$api.contacts.goods()
        if (result.code === 20000) {
          this.plainOptions = result.data.goods.map((item, index) => {
            return {
              ...item,
              Newindex: index + 1,
              images: item.img.split(',')[0]
            }
          })
          this.maxOrder = result.data.maxQuantity
          this.minOrder = result.data.maxQuantity
        } else {
          Toast(result.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    changehandl (val) {
      console.log(val)
    },
    changeItem (val) {
      const arr = val.img.split(',')
      console.log(arr)
      // this.ImagePreview.push(val.img)
      ImagePreview({
        images: arr,
        closeable: true
      })
    },
    async onSubmit () {
      // const arr = this.$store.state.checkId
      const arr1 = this.$store.state.couponId
      const params = {
        consigneeAddress: this.consigneeAddress, // 详细地址
        consigneeCity: this.consigneeCity, // 市
        consigneeCountry: this.consigneeCountry, // 县
        consigneeProvince: this.consigneeProvince, // 省
        consigneeName: this.consigneeName,
        consigneePhone: this.consigneePhone,
        couponId: arr1.couponId,
        goodsIdList: (this.checkedList).map(item => {
          return item.id
        })
      }
      const orderList = await this.$api.contacts.order(params)
      if (orderList.code === 20000) {
        // 获取当前URL
        const currentUrl = window.location.href
        // 提取URL的后缀部分
        const suffixIndex = currentUrl.lastIndexOf('/')
        const suffix = suffixIndex !== -1 ? currentUrl.slice(suffixIndex) : ''
        this.$store.commit('setmyOrderId', { orderId: orderList.data.orderId })
        // this.navigateWithSuffix('/public/giftSelect/changeSuccess')
        this.$router.push('/public/giftSelect/changeSuccess' + suffix)
      } else {
        Toast(orderList.message)
      }
    },
    async loadAreas (parentId) {
      const params = {
        parentId: parentId
      }
      const result = await this.$api.contacts.areas(params)
      if (result.code === 20000) {
        this.areaList.province_list = result.data.areas
        this.currentLevel = result.data.level
        // console.log(result, '333')
      } else {
        Toast(result.message)
      }
    },
    async handleChange (value, selectedAddress) {
      // let arr = ''
      if (this.currentLevel === 1) {
        const params = {
          parentId: selectedAddress[0].code
        }
        const result = await this.$api.contacts.areas(params)
        if (result.code === 20000) {
          this.areaList.city_list = result.data.areas
          this.currentLevels = result.data.level
          if (result.data.level === 2 && selectedAddress[1] !== undefined) {
            const paramss = {
              parentId: selectedAddress[1].code
            }
            const result = await this.$api.contacts.areas(paramss)
            if (result.code === 20000) {
              this.areaList.county_list = result.data.areas
            } else {
              Toast(result.message)
            }
          }
        } else {
          Toast(result.message)
        }
      }
    }
  },
  watch: {
    checkedList (val, newVal) {
      this.minOrder = this.maxOrder - val.length
    }
    // currentLevel (newVal) {
    //   console.log('2121')
    //   if (newVal !== null) {
    //     this.loadAreas(newVal) // 当当前级别变化时，加载下一级地区列表
    //   }
    // }
  },
  created () {
    this.request()
    this.loadAreas(0)
  }
}
</script>
<style scoped>
.wrapper {
  background-color: #d8d8d8;
}
.contenttow {
  position: relative;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
}
.top {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(255, 44, 0);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.top-mune {
  position: absolute;
  color: #fff;
  font-size: 16px;
  line-height: 45px;
  margin-left: 10px;
}
.menu {
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
}
.content {
  width: 100%;
}
.item {
  display: flex;
  height: 130px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 10px;
  .item-text {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }
}
.itemto {
  display: flex;
  height: 130px;
  background-color: #ccc;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 10px;
  .item-text {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }
}
h4 {
  margin-left: 10px;
  border-left: solid 8px red;
  padding-left: 8px;
}
.info {
  background-color: #fff;
  /* height: 500px; */
  margin: 0 10px;
  border-radius: 10px;
  padding-top: 10px;
}
.item-p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-intro {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
