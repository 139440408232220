<template>
  <div class="wrapper">
    <div class="top">
      <span>查看物流</span>
    </div>
    <div class="content">
      <div style="font-size: 24px; margin-top: 40px;">兑换成功</div>
      <div class="content-top">物流信息</div>
      <div class="content-bottom">
        <div style="text-align: center;margin-top: 50px; font-weight: 600;">
          <p style="font-size: 20px;" v-if="isShow">暂无物流信息,请隔段时间再查询</p>
          <p style="font-size: 20px;" v-if="!isShow">
            <span>{{ this.orderDetail.LogisticsCode.logisticsCompany }}:</span>&nbsp;
            <span id="textToCopy" style="font-size: 12px;">{{ this.orderDetail.LogisticsCode.logisticsCode }}</span>&nbsp;
            <span id="copyButton" @click="handleCopy" style="font-size: 12px; cursor: pointer; color: #3F73FA;">复制</span>
          </p>
          <p style="margin-top: 10px;font-size: 14px;">在首页再次输入卡号卡密可查看物流信息</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
export default {
  components: {},
  data () {
    return {
      isShow: true,
      orderDetail: []
    }
  },
  computed: {},
  methods: {
    handleCopy () {
      // 获取要复制的文本
      var textToCopy = this.orderDetail.LogisticsCode.logisticsCode
      // 创建一个临时的textarea元素
      var tempTextarea = document.createElement('textarea')
      // 将要复制的文本设置到临时textarea元素中
      tempTextarea.value = textToCopy
      // 将临时textarea元素添加到文档中
      document.body.appendChild(tempTextarea)
      // 选中临时textarea元素中的文本
      tempTextarea.select()
      // 执行复制操作
      document.execCommand('copy')
      // 移除临时textarea元素
      document.body.removeChild(tempTextarea)
      // 显示复制成功的提示信息
      Notify({ type: 'success', message: '物流单号已复制到剪贴板！' })
    }
  },
  mounted () {},
  created () {
    this.orderDetail = this.$store.state.LogisticsCode
    console.log(this.orderDetail, '3366')
    if (this.orderDetail.LogisticsCode.status === 1) {
      this.isShow = true
    } else {
      this.isShow = false
    }
  }
}
</script>
<style scoped lang="scss">
.top {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(255, 44, 0);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.wrapper {
    background-color: #d8d8d8;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content-top {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
    color: #fff;
    background-color: #FF2C00;
    width: 300px;
    height: 70px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 24px;
}
.content-bottom {
    width: 280px;
    height: 200px;
    background-color: #fff;
}
</style>
